<template>
  <div class="container">
    <navbar></navbar>
    <div class="content py-20 px-15">
      <p class="bold fs-xxl t-center m-b-15">{{ $t("market.gameDetail") }}</p>
      <van-skeleton
        title
        avatar
        :row="1"
        :loading="skeletoning"
        avatar-size="50px"
      >
        <van-card :thumb="gameDetail.remote_url">
          <template #title>
            <p class="fs-lg bold m-t-5">
              {{ lang == "zh" ? gameDetail.name : gameDetail.name_en }}
            </p>
          </template>
          <template #desc>
            <div class="fs-lg t-color2 m-t-10 lh-3 d-flex flex-wrap text">
              <span
                class="info word"
                v-html="lang == 'zh' ? gameDetail.info : gameDetail.info_en"
              ></span>
              <span class="t-color4 m-l-3" @click.once="expand" v-if="isexpand"
                >[{{ $t("market.expand") }}]</span
              >
            </div>
          </template>
        </van-card>
      </van-skeleton>
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :pulling-text="$t('public.pulling')"
        :loosing-text="$t('public.loosing')"
        :loading-text="$t('public.loading')"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('public.nomores')"
          :loading-text="$t('public.loading')"
          :offset="100"
          @load="onLoad"
        >
          <div class="d-flex flex-wrap jc-between px-25">
            <div
              v-for="(item, index) in list"
              :key="index"
              class="
                list_item
                m-t-15
                d-flex
                flex-column
                ai-center
                jc-center
                bg-color6
              "
              @click="toDetail(item)"
            >
              <van-image :src="item.remote_url" width="96px" height="80px" />
              <div class="lh-4 m-t-5 t-center">
                <p class="fs-lg">
                  {{ lang == "zh" ? item.nft_name : item.nft_name_en }}
                </p>
                <p class="fs-lg t-color5">{{ item.price | cutZero }} BNB</p>
              </div>
            </div>
          </div>
        </van-list>
        <!-- $t('market.tips') -->
        <van-empty
          v-if="list.length <= 0"
          class="custom-image"
          :image="require('@/assets/empty.png')"
          description=""
        />
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      finished: false,
      refreshing: false,
      totalPage: 1,
      pageNumber: 1,
      list: [],

      gameDetail: {},
      skeletoning: true,
      isexpand: true,
    };
  },
  computed: {
    lang() {
      return this.$storage.get("lang");
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    //页面初始化之后会触发一次，在页面往下加载的过程中会多次调用【上拉加载】
    onLoad() {
      setTimeout(() => {
        if (this.pageNumber >= this.totalPage) {
          this.finished = true;
          this.loading = false;
        } else {
          this.pageNumber++;
          this.getList();
        }
      }, 500);
    },
    init() {
      this.totalPage = 1;
      this.pageNumber = 1;
      this.getList();
    },
    onRefresh() {
      setTimeout(() => {
        this.totalPage = 1;
        this.pageNumber = 1;
        this.getList();
      }, 1000);
    },
    getList() {
      let that = this;
      this.loading = true;
      this.$axios
        .get(this.$api.gamefinftList + "/" + this.$route.query.id, {
          page: this.pageNumber,
          page_size: 8,
        })
        .then((res) => {
          if (res.code == 200) {
            that.$toast.clear();
            this.loading = false;
            this.finished = false;
            this.refreshing = false;
            this.gameDetail = res.data.game;
            let arr = res.data.nfts.list;
            if (this.pageNumber == 1) {
              this.list = arr;
              this.totalPage = res.data.nfts.totalPage;
            } else {
              this.list.push(...arr);
            }
            this.skeletoning = false;
          } else {
            this.loading = false;
            that.$toast(res.msg);
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    //NFT详情
    toDetail(item) {
      this.$router.push(
        "/nftDetail?id=" + item.id + "&ids=" + this.$route.query.id
      );
    },
    //展开
    expand() {
      let e = document.getElementsByClassName("info")[0];
      e.removeAttribute("class");
      this.isexpand = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-card__thumb img {
  object-fit: fill;
}
/deep/ .van-card {
  background-color: transparent;
  color: #fff;
  padding: 0 0 10px 0;
  border-bottom: 1px solid rgba($color: #9998c2, $alpha: 0.3);
}
/deep/ .van-card__content {
  display: flex;
  flex-direction: column;
  // justify-content: center;
}
/deep/ .custom-image .van-empty__image {
  width: 300px;
  height: 225px;
}
.info {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.purchase {
  border-radius: 5px;
}
.list_item {
  width: 47%;
  height: 150px;
  border-radius: 10px;
  overflow: hidden;
}
.text{
  word-wrap: break-word;
  table-layout: fixed;
  word-break: break-all;
}
</style>